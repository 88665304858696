


import React from 'react';

import {Link} from 'react-router-dom';

import {getExcerpt} from '../../Utility/StringOps';

const Article=(props)=>{

  const post=props.post;

  const template=props.template;
  const directory=props.directory;

  const getContent=()=>{

  var content=null;
  if(template=='summary'){
        
    content=(
        <div className="col d-flex justify-content-center">
            <div className="card card-wrapper card_article-wrapper" >
                <div className="card-content text-start">
                        <Link to={'/'+post.link}>
                            <img  loading="lazy"  src={`${ directory + post.image_file}`} className="card-image"  />   
                        </Link>
                        <div className="card-body card_article-body">
                            <h4>{post.headline}</h4>
                            <h5 className="card-text">{post.excerpt}</h5>
                        </div> 
                        <div className=" d-flex flex-row-reverse p-3">
                            <Link to={'/'+post.link} className="card-link-more" >{post.button_label}&nbsp;  
                                <i className="bi-arrow-right"></i>
                            </Link>
                        </div>

                </div>     
            </div>
        </div>
    );
  }

  if(template=='detail'){
    console.log('article detail');
    console.log(post);
    content=(
          
        <div className="card card-wrapper-horiz" >
            <div className="card-content text-start">
                <div className="row ">
                    <div className="col-md-6 p-0 ">
                        <Link to={'/'+post.link}>
                            <img  loading="lazy" src={`${ directory + post.image_file}`} className="card-image-horiz " alt="..."/>
                        </Link>
                    </div>
                    <div className="col-md-6">
                        
                        <div className="card-body">
                            <h4 className="card-title">{post.headline}</h4>
                            <h5 className="card-text"> <div dangerouslySetInnerHTML={{__html: post.body}}></div></h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
  }

    return content;
  }

   

  return (  
          
    <React.Fragment>
        {getContent()}
    </React.Fragment>
           
            
       );
  
 }


export default Article;







  