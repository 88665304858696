import React from 'react';
import MenuItem from './MenuItem';


const Menu=(props)=>{
  
  const menus=props.menus;
  const menuName=props.menuName;
  var menu=false;
  if(menus){menu=menus.find(function(tMenu){return tMenu.name==menuName});}
  
 
  return(

    <React.Fragment>
      {menu ? (
        
        menu.elements.map((element,e)=>(

              <React.Fragment key={e}>
                {element.type=='button' ? (<MenuItem element={element} key={e}></MenuItem>):null}
                {element.type=='nav-link' ? (<MenuItem element={element} key={e}></MenuItem>):null}
                {element.type=='vendor-profile' ? (<MenuItem element={element} menus={menus}  key={e}></MenuItem>):null}
                {element.type=='policy-link' ? (<MenuItem element={element} menus={menus} key={e}></MenuItem>):null}
                {element.type=='header' ? (
                  <div>
                    <h5 key={e}>{element.label.toUpperCase()} </h5> 
                    {element.sub_elements ? (element.sub_elements.map((sub,s)=>(
                      <MenuItem element={sub} key={s}></MenuItem>
                    ))):null}
                  </div>
                ):null}

                {element.type=='dropdown' ? (
                  
                  
                  
                  <div> 
                  <ul>
                    <li className="nav-item dropdown">
                      <a className=" nav-link dropdown-toggle " href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                              {element.label}
                      </a>
                      <ul className="dropdown-menu">
                        {element.sub_elements.map((sub,s)=>(
                                <MenuItem element={sub} key={s}></MenuItem>
                        ))}
                      </ul>
                    </li>

                  </ul>
                </div>
                  
                ):null}





              </React.Fragment>
            ))

      ):null}     
    

    </React.Fragment>
  );
}  
   
export default Menu;





