
    
    import React from 'react';
        
    import {getRequest} from '../Utility/RequestTools';
    import { useParams } from 'react-router-dom';
    import goAPI2 from '../Services/goAPI2';
    import { recordActivity } from '../Utility/Activities';
    import { setReferral } from '../Utility/Activities';
    
    import Layout from '../Sections/Layout/Layout';
    import FormBlock from '../Sections/Store/FormBlock';
    import ContentBlock from '../Sections/Store/ContentBlock';
    import SearchBar from '../Sections/Store/SearchBar';
    import Showcase from '../Sections/Store/Showcase';
    import FeaturedPost from '../Sections/Store/FeaturedPost';
    import LogoBlock from '../Sections/Store/LogoBlock';
    import Profile from '../Sections/Store/Profile';

    import PostList from '../Sections/Manager/PostList';
    import PostEdit from '../Sections/Manager/PostEdit';
    import PostImage from '../Sections/Manager/Image/PostImage';
    import PostTags from '../Sections/Manager/PostTags';
    import { unpack } from '../Utility/CustomFields';
    
    const AppOne =()=>{

      const {d1}=useParams();
      const {d2}=useParams();
      const {d3}=useParams();
      const {d4}=useParams();
      const {d5}=useParams();
      const {d9}=useParams();
      
      const [page, setPage]=React.useState(false);
      
      const rqst=getRequest();
      var path=rqst.path;
      var testDomain=false;



      
      //testDomain='bretthersley.com';
      //testDomain='bouncedemo.com';
      //testDomain='bouncehouse.com';

     
      React.useEffect(()=>{
        
        //get content
        var qString='exp/pages/?path='+path;
        if(testDomain){qString=qString + '&td='+testDomain;}
        
        goAPI2.get(qString)
        
              .then(response => {
                if(response.data){
                  setPage(response.data);
                }})
                .catch(function (error) {
                
              })   
      },[d1,d2,d3,d4,d5,d9]);
     
      
      if(page){        
        //capture session
        localStorage.setItem('sessionid',page.session_id);  
        setReferral();
        var activity={};
        activity.name='visit';
        recordActivity(activity);
      }  

      document.title=page.title;

      const parts=page.parts;  
      
      //report the event
      






      const getSection=(part)=>{
        
        var section=false;
        
        part=unpack(part);
        if(part.type=='form'){ section=<FormBlock part={part} key={part.id} testDomain={testDomain}></FormBlock>;}
        if(part.type=='content'){section=<ContentBlock part={part} key={part.id} testDomain={testDomain}></ContentBlock>;}
        if(part.type=='searchbar'){section=<SearchBar part={part} page={page} key={part.id} testDomain={testDomain}></SearchBar>;}
        if(part.type=='showcase'){section=<Showcase part={part} page={page}  key={part.id} testDomain={testDomain} ></Showcase>;}
        if(part.type=='featuredpost'){ section=<FeaturedPost part={part} page={page} key={part.id} testDomain={testDomain}></FeaturedPost>;}
        if(part.type=='postlist'){ section=<PostList part={part} page={page} key={part.id} testDomain={testDomain}></PostList>;}
        if(part.type=='logo-block'){ section=<LogoBlock part={part} page={page} key={part.id} testDomain={testDomain}></LogoBlock>;}
        if(part.type=='profile'){ section=<Profile part={part} page={page} key={part.id} testDomain={testDomain}></Profile>;}
        return section;
      }

      return(        
        <div>
          {page  ? (
            <Layout page={page} testDomain={testDomain}> 
              {parts.map((part,p)=>(<React.Fragment key={p}>{getSection(part)}</React.Fragment>))              
              }
            </Layout> ) 
          :null}  
        </div>
      );
    }


export default AppOne;