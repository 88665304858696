import React from 'react';


import Validate from '../../Utility/Validation';
import DisplayAlert from './DisplayAlert';
import goAPI2 from '../../Services/goAPI2';
import {getRequest} from '../../Utility/RequestTools';
import {Form} from 'react-bootstrap';
import FormElement from './Elements/FormElement';
import {unpack} from '../../Utility/CustomFields';
import { getReferral } from '../../Utility/Activities';

import {listAllFields} from '../../Utility/CustomFields';

const FormMgr =(props)=>{

  const [values, setValues]=React.useState({});
  const [alert, setAlert]=React.useState();
  const [errors, setErrors]=React.useState({});
  
  const form=props.form;
  var errorCount=Object.keys(errors).length;
  
  const formName=form.endpoint.name;

  const fields=form.fields;

  const post=props.post;


 
  var allFields=listAllFields(fields); 


  var redirect=form.endpoint.redirect;
  if(props.redirect){
    if(props.redirect.length >0){redirect=props.redirect;
    }
  }


  const initValues=()=>{

    var newValues={};
    
    if(post){
      //if post is provided in props then assume edit post
      newValues=unpack(post);


    }else{
          newValues=values;
          for (const [key, value] of Object.entries(values)) {
                newValues[key]='';
              }
          }
          
    setValues(newValues)


    }


 React.useEffect(()=>{
      initValues();
    
    },[]);

  

  const handleChange = evt => {
    
    var newValues={...values};
    var {name, value} = evt.target;
    newValues[name]=value;
   
    setValues(newValues);
    
    
  }
  
  const handleBlur = evt => {
    
    const { name,value: newValue } = evt.target;

 
    // check for a new error
    const error = Validate(name, allFields, newValue);
    var currentErrors={...errors};
    if(error!==null){currentErrors[name]=error;
    }else{//clear error
            if(currentErrors[name]){delete currentErrors[name];}
          }
    setErrors(currentErrors);
    
  
  }
  
  

  // form submit handler
  const handleSubmit = (evt) =>{
 
    evt.preventDefault();
    
    //check errors again
    var submitErrors={};
    
    allFields.map((field, f)=>{
    // check for a new error
    var error=false;

    if(field.type!=='hidden'){
        error = Validate(field.name, allFields, values[field.name]);
        if(error){
          submitErrors[field.name]=error;
        }
      }
    })

 
    setErrors(submitErrors);
    
    if(Object.keys(submitErrors).length ==0){
      var rqst=getRequest();
      
      //separate custom fields
      var realValues=values;
      
      //add defaults 
      if(props.defaults){
        var defaults=props.defaults;
                    
        for (const [key, value] of Object.entries(defaults)) {
          realValues[key]=value;
        }
      }
                
      //add id to paramstring if existing record
      if(values.id){realValues['id']=values.id;}

      //if post name listed as parameter add to 
        if(rqst.params){
          if(rqst.params['postid']!==''){
            realValues['targetpostid']=rqst.params['postid'];
          }
        }
              
      var postValues=realValues;

      //adjust for activities
      if(form.endpoint.post_url=='cdp/activities/'){
        var activity={};
        const rqst=getRequest();
        activity.source=rqst.domain;
        var referral=getReferral();
        if(referral){
          activity.referral_source_id=referral.value;
          activity.referral_source=referral.key;
          activity.name=formName;
        }
        activity.params=realValues;
        //set to real values to post to submit to api
        postValues=activity;
      }
      
      //Submit to API 
      var paramString=JSON.stringify(postValues);
          
            
       goAPI2.post(form.endpoint.post_url,paramString)
                  
        .then(response => {
          if(response.data!==false){
                      
            if(form.endpoint.redirect.length>0){
              var redirect=form.endpoint.redirect;
              if(redirect.slice(0,1)!=='/'){
                redirect='/'+redirect;  
              }
              window.location.href=redirect;
            }else{
                  initValues();
                  setAlert({"outcome":true}); 
                }
                      
          }else{
                //it is false
                setAlert({"outcome":false}); 
                }
          })

          .catch(function (error) {
            if(props.callBack){props.callBack(error.response.status);}
              setAlert({"outcome":false});
              console.log(error);
          })  
            
    }

  } //handle submit
            
  return (
           <div>
            {form ? (<div>
        
              <Form id={formName}  key={formName} >
              
                {fields.map((field,f)=>(
              
                  <div className="row justify-content-center" key={f}>
                    <div className="col">
                      <FormElement formField={field} 
                      
                        value={values[field.name]}
                        error={errors[field.name]}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                       // form={form}
                        id={field.id}
                        key={f}
                        errorCount={errorCount}
                        //tags={tags}
                                >
                      </FormElement>
                    </div>
                    {field.subfields ? field.subfields.map((sub,s)=>(
                         <div className="col" key={s}>
                            <FormElement formField={sub} 

                            
                              value={values[sub.name]}
                              error={errors[sub.name]}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              handleSubmit={handleSubmit}
                              //form={form}
                              id={sub.id}
                              key={s}
                              //tags={tags}
                                                            
                            ></FormElement>
                          </div>

                    )) : null}
                  </div>
                ))}
                
              </Form>
            
            
              <DisplayAlert  alert={alert} ></DisplayAlert> 
              
            </div>) : null} 
          </div>
  );
}  

export default FormMgr;