


import React from 'react';


//custom post types
import Attraction from '../../Component/Templates/Attraction';
import Article from '../../Component/Templates/Article';
import Ad from '../../Component/Templates/Ad';
import Policy from '../../Component/Templates/Policy';

import Skill from '../../Component/Templates/Skill';
import Project from '../../Component/Templates/Project';
import { unpack } from '../../Utility/CustomFields';

const DisplayPost=(props)=>{

  const post=unpack(props.post);
   
  const directory=props.page.directory;
  
   const getContent=()=>{

      const postType=post.type;
 
      var content=(

        <React.Fragment>
     
          {postType =='attractions' ? (<Attraction post={post} isBroker={props.page.is_broker} directory={directory} template={props.template}></Attraction>):null}
          {postType =='articles' ? (<Article post={post} directory={directory} template={props.template}></Article>):null}
          {postType =='ads' ? (<Ad post={post} directory={directory} template={props.template}></Ad>):null}
          {postType =='policies' ? (<Policy post={post}  directory={directory} template={props.template}></Policy>):null}
          
          {postType =='skills' ? (<Skill post={post}  directory={directory} template={props.template}></Skill>):null}
          {postType =='projects' ? (<Project post={post}  directory={directory} template={props.template}></Project>):null}
          
        </React.Fragment>
      );

      return content;
    
    }

    return (  
              <React.Fragment>

                  {post ? getContent():null}

              </React.Fragment>
            );
  
}

export default DisplayPost;







  