


import React from 'react';

import {Link} from 'react-router-dom';
import { getRequest } from '../../Utility/RequestTools';
import {Button} from 'react-bootstrap';


const Ad=(props)=>{
    
  const post=props.post;
  const directory=props.directory;
  const rqst=getRequest();
  
  var backLink=false;
  if(rqst.params){backLink=rqst.params['path']};

  const template=props.template;
  const getContent=()=>{

    var content=null;
    if(template=='summary'){
        
        content=(
            <div className="col">
                <div className="card card-wrapper" >
                    <div className="card-content text-start">
                        <Link to={post.link}>
                            <img  loading="lazy"  src={`${ directory + post.imagefile}`} className="card-image"  />   
                        </Link>
                        <div className="card-body">
                            <h4>{post.headline}</h4>
                            <h5 className="card-text">{post.body ? post.body.slice(0,50)+'...':null}</h5>
                                
                            <div className=" d-flex flex-row">
                                <Link to={'/'+ post.link} className="card-link-more" >Read More&nbsp;  
                                <i className="bi-arrow-right"></i></Link> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
        );
    }

    if(template=='ad-left'){
        
        content=(
              
            <div className="card card-wrapper-horiz" >
                <div className="card-content text-start">
                    <div className="row ">
                        <div className="col-md-4 p-0 ">
                            <Link to={'/'+post.link}>
                                <img  loading="lazy" src={`${ directory + post.image_file}`} className="card-image-horiz " alt="..."/>
                            </Link>
                        </div>
                        <div className="col-md-8">
                            
                            <div className="card-body">
                                <h4 className="card-title">{post.headline}</h4>
                                <h5 className="card-text"> {post.subhead}</h5>
                            </div>
                           
                            <Button className="button-icon mx-auto">Contact</Button>
                        
                        </div>

                    </div>
                </div>
            </div>
            );
      }
      if(template=='ad-right'){
        
        content=(
              
            <div className="card card-wrapper-horiz" >
                <div className="card-content text-start">
                    <div className="row ">
                       
                        <div className="col-md-8">
                            
                            <div className="card-body">
                                <h4 className="card-title">{post.headline}</h4>
                                <h5 className="card-text"> {post.subhead}</h5>
                            </div>
                           
                            <Button className="button-icon mx-auto">Contact</Button>
                        
                        </div>
                        <div className="col-md-4 p-0 ">
                            <Link to={'/'+post.link}>
                                <img  loading="lazy" src={`${ directory + post.image_file}`} className="card-image-horiz " alt="..."/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            );
      }

    return content;
  }

   

  return (  
          
    <React.Fragment>
        {getContent()}
    </React.Fragment>
           
            
       );
 }


export default Ad;







  