
import React  from 'react';
import {Form,Button} from 'react-bootstrap';
import AutoComplete from '../../Component/Form/Elements/AutoComplete';  
import locations from '../../../tags.json';
import goAPI2 from '../../Services/goAPI2';
import tags from '../../../tags.json';
import { useState, useEffect } from 'react';
import { recordActivity } from '../../Utility/Activities';
import {redirect} from 'react-router-dom';

const SearchBar=(props)=>{

  const [values, setValues]=useState({});
  const [serviceList,setServiceList]=useState([]);

  const page=props.page;
  const broker=page.is_broker;
 
  useEffect(() => {
    //get service list
    if(broker){
      const brokerServices=tags.filter(tag=>tag.type=='service');
      setServiceList(brokerServices);

    }else{
          const fetchData = async () => {
            const tagsResp = await  
              goAPI2.get('exp/tags/?type=service')
            setServiceList(tagsResp.data);
          }
        fetchData();  
        }
    
    //set values 
    if(page.name=='attractions'){
      var serviceTag=page.tag1;
      var locationTag=page.tag2;
      setValues({location:locationTag,service:serviceTag});
    }
  }, []);


  const handleChange = (e) => {
    
    const { name, value: newValue } = e.target;
        
    // save field values
    var newValues=values;
    newValues[name]=newValue;
    setValues(newValues);
    console.log(values);
  }


  const handleSubmit=(e)=>{
    e.preventDefault();
    //redirect to new url
    //record activity
    var activity={};
    activity.name='search';
    activity.location=values.location;
    activity.service=values.service;
    recordActivity(activity);


    if(broker){
      
      window.location.href="/attractions/"+values.service+"/"+values.location+"/";
    }else{
      window.location.href="/attractions/"+values.service+"/";
      }
    }    


  return (
      <div className="container search-wrapper text-center">
        <div className="row justify-content-center">
          <div className="col-md-4 ">
              <h2>Fun Finder</h2>
                
                {broker ? <React.Fragment>
                            <h5 className="pb-3">Find rentals and other services in your area</h5> 
                            <Form>
                              <Form.Select   name="service" className="mb-3" maxLength="350" onChange={handleChange} valtype='select' >
                                <option key = 'blankChoice' hidden value>Select Service</option>
                                {serviceList.map((service,s)=>(<option key={s} value={service.name}>{service.label} </option>))}
                              </Form.Select>

                              <AutoComplete suggestions={locations}  handleChange={handleChange} placeholder="City, State"  fieldName="location" value="Test, TX"></AutoComplete>

                              <button type="submit" value="Submit"  onClick={handleSubmit} className="button-icon w-button m-3">Search</button>
                            </Form>  
                          </React.Fragment>
                          :<React.Fragment>
                            
                            <h5 className="pb-3">Select a service to get started</h5> 
                            <Form>
                              
                              <Form.Select   name="service" className="mb-3" maxLength="350" onChange={handleChange} valtype='select' >
                                <option key = 'blankChoice' hidden value>Select Service</option>
                                {serviceList.map((service,s)=>(<option key={s} value={service.name}>{service.label} </option>))}
                              </Form.Select>
                              <div className=" d-flex flex-row">
                              <button type="submit" value="Submit"  onClick={handleSubmit} className="button-icon mx-auto  m-3">Search</button>
                           </div>
                           </Form>  
                          </React.Fragment>
                }
                  
          </div>     
        </div>
      </div>
  );
}

export default SearchBar;