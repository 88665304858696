import React, { useState } from "react";

import goAPI2 from "../../../Services/goAPI2";
import {Container,Button} from 'react-bootstrap';
import EasyCrop from './EasyCrop';
import {getRequest} from '../../../Utility/RequestTools';
import './Image.css';

function PostImage() {
 

  const [image, setImage] = useState(null);
 
    
  const rqst=getRequest();
  const postId=rqst.params['id'];
  const postType=rqst.params['posttype'];
 
  const handleImageUpload = async (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
  };

  const handleSave=(croppedImage)=>{

    
    var formData = new FormData(); 
    
    formData.append("asset", croppedImage.blobfile, 'blobfile.jpg');
    //formData.append("imagealt", 'my alt text');
    //formData.append("imagename", preview.name);
    formData.append("postid", postId);
  
    const headers={"Content-Type": "multipart/form-data"};
    goAPI2.post('exp/images/',formData,headers)
    
    .then(response => {
      if(response.data){
        
        window.location.href="/manager/list/?posttype="+postType;
      }})
    
    .catch(function (error) {
      console.log("Image did not upload");
      console.log(error);
    })   

  }

  const handleCancel=()=>{

      window.location.href="/manager/list/?posttype="+postType;
  }



  return (
    <div className="App">
      <header className="App-header">
        <label className="_coverImage-holder">
          Upload Image
          <input
            type="file"
            name="cover"
            onChange={handleImageUpload}
            accept="image/*;capture=camera"
            style={{ display: "none" }}
          />
        </label>
        <EasyCrop image={image} handleSave={handleSave}   />
        <Button className="m-3" onClick={handleCancel}>cancel</Button>
      </header>
      
    </div>
  );
}


export default PostImage;
