export function getExcerpt(rawString,max){ 
    var shortString="Enter description...";
    
    if(rawString!==null){
                
        shortString = rawString.replaceAll( /(<([^>]+)>)/ig, '');

        if(shortString.length>max){
            var working=shortString.substring(0,max-3);
            var lastSpace=working.lastIndexOf(" ");
            shortString=working.substring(0,lastSpace) + "...";
        }
    }   

   
    return shortString;
}

export function convertToName(label){ 
    
    var newString=label.toLowerCase();
    newString=newString.replaceAll(' ','-');
    newString = newString.replace(/[^a-z0-9]/, '');


    return newString;
}

export function properCase(value){ 
    
    var newString='';
    if (value){
        newString=value.charAt(0).toUpperCase() + value.slice(1);

    }
    
    
    return newString;
}

export function plural(value){ 
   
    var newString='';
    if(value){
        if (value.slice(-1)=='y"'){
            newString=value.slice(0,value.length-1) + "ies";
            
        }else{
            newString=value + 's';
            }
    }
    return newString;
}
export function singular(value){ 
    
    var newString='';
    if (value){
        newString=value.slice(0,-1);
    }
    
    return newString;
}