
const Validate = function(name, fields, value){



  var targetField=false;

  targetField=fields.find(field=>field.name==name);
    
  if(!targetField){
    return null;
  }  

  var required=targetField.required;
  var inputType=targetField.inputtype;
  var label=targetField.label;

  //check required
  if(required==1){
    var valid=true;

    if(value==undefined){
      valid=false;
    }else{
        //spaces disregard
        var noSpace=value.trim();
        if (noSpace === '') {
        valid=false;
        }
      }    
    if(valid==false){
      return `${label} is required`;
    }
  }


  //specific checks
  if(value!==undefined){
    //value must be defined for format validation
 
    if(inputType=='text'){
  
    
      if (/[^a-zA-Z0-9 -?']/.test(value)) {
        return 'Invalid characters';
      }
      
      return null;
    }
    
    if(inputType=='textarea'){
      
    
      if (/[^a-zA-Z0-9? -]/.test(value)) {
        return 'Invalid text area characters';
      }
      
      return null;
    }

    if(inputType=='phone'){
    
        var numbers = value.replace(/[^0-9]/g,'');
        if(numbers.length!==10){
          return ' Please enter a valid phone. xxx-xxx-xxxx';
        }
      
      return null;
    }

    if(inputType=='password'){
            
        if (/[^a-zA-Z0-9.$? @-]/.test(value)) {
          return 'Invalid characters';
        }
        if(value.length <8){
          return 'Must be 8 characters';
        }
      
      return null;
    }

    if(inputType=='email'){
      if (
        /^[\w-]+(?:\.[\w]+)*@(?:[\w-]+\.)+[\w-]{2,6}$/.test(
          value,
        )
      ) {
        return null;
      }

      return 'Please enter a valid email';
    }
  }//value not undefined

  return null;

}

export default Validate;