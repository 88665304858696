// First we need to import axios.js
import axios from 'axios';
import sessionId from '../Views/AppOne';

// Next we make an 'instance' of it
const goAPI2 = axios.create({
// .. where we make our configurations
    baseURL: 'https://go.en0v8.com/v2/'
});

// Where you would set stuff like your 'Authorization' header, etc ...
goAPI2.defaults.headers.common['Authorization'] = 'bouncehousekey';
goAPI2.defaults.headers.common['Session-Id']=localStorage.getItem('sessionid');
//goAPI2.defaults.headers.common['Session-Id']='yoyo';
//console.log('hello axious'+sessionId);

// Also add/ configure interceptors && all the other cool stuff

export default goAPI2;