import React from 'react';
import{Routes, Route} from 'react-router-dom';
import AppOne from './containers/Views/AppOne';
import './App.css';

const App=()=>{
  
  return (
    <Routes>
      
       <Route path=":d1/:d2/:d3/:d4/:d5/:d9" element={<AppOne />}/>
       <Route path=":d1/:d2/:d3/:d4/:d9" element={<AppOne />}/>
       <Route path=":d1/:d2/:d3/:d9" element={<AppOne />}/>
       <Route path=":d1/:d2/:d9" element={<AppOne />}/>
       <Route path=":d1/:d9" element={<AppOne />}/>   
       <Route path="/:d9" element={<AppOne />}/> 
       <Route path="/" element={<AppOne />}/> 
   </Routes>);
 }
export default App;