
import React from 'react';
import {Alert} from 'react-bootstrap';

const DisplayAlert =(props)=>{

 
  //expects optional messages(obj) with failure and success

 const [status,setStatus]=React.useState('ready');
 const [data,setData]=React.useState({"display":false,"success":undefined});


 var outcome=undefined;

 var alert=props.alert;


 React.useEffect(() => {
   
    if(data.display==false && alert!==undefined){

      //activate
      setData({"display":true, "success":alert.outcome})

      const timer = setTimeout(() => {
        setData({"display":false, "success":undefined});}, 5000);
        return () => clearTimeout(timer);
    }  
    
  }, [alert]);


  const getMessage=()=>{
    var message='default message';
    if(alert.outcome==true)
      {message='Success';
      }else{message='Operation not completed.';
            }
    if(alert.message){message=alert.message;}

    return message;
  }


  const getVariant=()=>{
    var variant='success';
    
    if(outcome=='failure'){variant='warning';}

    return variant;
  }

  return(<div>
           {data.display==true? <Alert variant={getVariant()}>{getMessage()}</Alert> : null}
          </div>
  );
}



export default DisplayAlert;