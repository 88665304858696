export function getRequest(){

    //define request
    var url=window.location.href;
    var rqst = {};

    var rawPath=url.split('?')[0];
    var rawParamString=url.split('?')[1];

    //remove prefixes from path
    var path=rawPath.replace('https://','');
    path=path.replace('http://','');
     
    
    //break up path
    var pathParts = path.split("/");
    rqst.domain=pathParts[0];
    rqst.dir1=pathParts[1];
    rqst.dir2=pathParts[2];
    rqst.dir3=pathParts[3];
    rqst.dir4=pathParts[4];
        
    if(rqst.dir4==undefined|| rqst.dir4==''){
        rqst.dir4=false;
        rqst.last=rqst.dir3;}
    if(rqst.dir3==undefined || rqst.dir3==''){
        rqst.dir3=false;
        rqst.last=rqst.dir2; }
    if(rqst.dir2==undefined || rqst.dir2==''){
        rqst.dir2=false;
        rqst.last=rqst.dir2;}


    //remove domain from path   
    path=path.replace(rqst.domain,''); 
    //remove leading slash from path
    if(path.slice(0,1)=='/'){path=path.slice(1,50);}
    //add trailing slash if missing
    if(path.slice(-1)!=='/'){path=path+ '/';}

    rqst.path=path;
    rqst.paramstring=rawParamString;
    rqst.longpath=path + '?'+rawParamString;
    
   //separate param string
    
    if(rawParamString){
        if(rawParamString.length>0){
        
            var paramParts=rawParamString.split("&");
            var params={};
            paramParts.map((pPart,p)=>{
                var part1=pPart.split("=")[0];
                var part2=pPart.split("=")[1];
                params[part1]=part2;
            })
            rqst.params=params;
        }
    }
   

    return rqst;

}  