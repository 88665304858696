
import React ,{Component} from 'react';
import {Col,Form,InputGroup,Button,FormControl} from 'react-bootstrap';
import AutoComplete from './AutoComplete';
import {Link} from 'react-router-dom';

const FormElement =(props)=>{
    
    const form = props.form;
    
    const formField=props.formField;
    const handleChange=props.handleChange;
    const handleBlur=props.handleBlur;
    const handleSubmit=props.handleSubmit;
   
  
    const [showTip,setShowTip]=React.useState(false);
  
   
    
    const name=formField.name;
    const label=formField.label;
    const value=props.value;
   
    const error=props.error;

    const getElement=()=>{

        var element=<em>element {name} not found</em>;
    
        //section
        if(formField.input_type==='section'){element=(<hr/>);}

        //title
        if(formField.input_type==='title'){element=(<h6>{label}</h6>);}
            
        //date
        if(formField.input_type==='date'){element=(  
            <Form.Group>
                <Form.Control type='date'   placeholder={label} name={name} onChange={handleChange} onBlur={handleBlur} className="mt-3" maxwidth="100" ></Form.Control>
            </Form.Group>
        );}
                
        //email
           
        if(formField.input_type==='email'){element=(  
            <Form.Group>
                {formField.showlabel ? <Form.Label>{formField.label}</Form.Label>:null}  
           
                <Form.Control type='email' placeholder={label} value={value} name={name} onChange={handleChange} onBlur={handleBlur} className="mt-3"  ></Form.Control>
            </Form.Group>
        );}


        //text
        if(formField.input_type==='text'){element=( 
            <Form.Control type='text'  placeholder={label} name={name} onChange={handleChange} onBlur={handleBlur} value={value}maxLength="350" className="mt-3" ></Form.Control>
        );}
  
        
        //phone
        if(formField.input_type==='phone'){element=( 
            <Form.Control type='text'  placeholder={label} name={name} onChange={handleChange} onBlur={handleBlur} value={value}maxLength="350" className="mt-3" ></Form.Control>
        );}
  

        //password
        if(formField.input_type==='password'){
            element=( 
            <Form.Control type='password'   placeholder={label} name={name} onChange={handleChange} onBlur={handleBlur}  value={value} maxLength="350" className="mt-3" >
            </Form.Control>
        );}

        //link
        if(formField.input_type==='link'){
            element=( 
                <div className="mt-2">
                    <Link to ={formField.default_value} className="footer_policy-link m-3">{formField.label}</Link>
                </div>
                );}

        //hidden
        if(formField.input_type==='hidden'){element=( 
            <Form.Control type='hidden'   placeholder={label} name={name} onChange={handleChange} 
                onBlur={handleBlur} value={value} className=".d-none">
            </Form.Control>
        );}

       

        //text area//as="textarea"
        if(formField.input_type==='text-area'){element=( 
                <Form.Group>
                    <Form.Control as='textarea' rows={3}    placeholder={label}  name={name}  onChange={handleChange} className="mt-3"
                    onBlur={handleBlur} value={value} maxLength="5000" >

                    </Form.Control>
                   
                </Form.Group>
               );}

        //text area xl
        if(formField.input_type==='text-area-xl'){element=( 
            <Form.Group>
                <Form.Control as='textarea' rows={50}   placeholder={label}  name={name}  onChange={handleChange} className="mt-3"
                onBlur={handleBlur} value={value} maxLength="5000" >

                </Form.Control>
               
            </Form.Group>
           );}
       

        //select
        if((formField.input_type==='select-list'||formField.inputtype==='select-tag')){
            var options=formField.options;
            
            
            
            
            element=( 
                <Form.Select  name={name} onChange={handleChange} onBlur={handleBlur}   value={value} placeholder={label} className="mt-3" maxLength="350" >
                    <option key = 'blankChoice' hidden value> {label} </option>
                    {options.map((option,o)=>(<option key={o} value={option.value}>{option.label} </option>))}
                </Form.Select>
            );
        }

        //file
        if((formField.input_type==='file')){
                element=( 
                
                <Form.File type="file" onChange={handleChange} valtype="file" label={label}></Form.File>
                 
        );}
 //image
 if((formField.inputtype==='image')){
    element=( 
        <input type="file" onChange={handleChange}  label={label} accept="image/*,.pdf" />
    
     
);}
        //location auto-complete   
      //  if((formField.input_type==='location')){

      //          var tags=props.tags;
      //          var locations=tags.filter((tag)=>tag.type=='location');
      //              element=( 
                        
       //                    <AutoComplete suggestions={locations} placeholder="City, State" handleChange={handleChange} fieldName={formField.name}></AutoComplete>
                     
       // );}
        
        //button
        if(formField.input_type==='button'){
            if(props.errorCount==0){

                element=(
                    <button type="submit" value="Submit"  onClick={handleSubmit} className="button-icon w-button m-3">{formField.label}</button>);
            }else{
                element=(<Button type="submit" value="Submit" disabled onClick={handleSubmit} className="button-icon w-button m-3">{formField.label}</Button>);
            }
        }

     
     
     return element;

    }

    return(
                
                <React.Fragment>
                   
                    {getElement()}
                    {formField.tip ? <em>{formField.tip}</em>  : null}
                    {error ? <div className="form-error-msg">{error}</div>:null}
                 
                </React.Fragment>
    );

    }
export default FormElement;

