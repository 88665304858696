
import React  from 'react';
import FormMgr from '../../Component/Form/FormMgr';
import { useState, useEffect } from 'react';
import goAPI2 from '../../Services/goAPI2';

const FormBlock=(props)=>{

  const [data, setData] = useState(false)
  
  const part=props.part;
  const postId=false;
  
  const formName=part.form_name;

 

  useEffect(() => {
    const fetchData = async () => {
      const formResp = await  
        goAPI2.get('exp/forms/?name='+formName)

      if(postId){
        const postResp=await  
        goAPI2.get('exp/posts/?id='+postId)
        setData({ post:  postResp.data, form: formResp.data });
      }else{
            setData({ post:  false, form: formResp.data });
            } 
    }
    fetchData();    
  }, [formName]);
  
 
  return (
    <div>
    {data ?
      <div className="container text-center form-wrapper">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <h2>{part.headline}</h2>
            <h5 className="pb-3">{part.subhead}</h5>
            <FormMgr  form={data.form}></FormMgr> 
            
          </div>     
        </div>
      </div> : null}
     </div> 
  );
}

export default FormBlock;