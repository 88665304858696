
import React from 'react';
import { useState,useEffect } from 'react';
import goAPI2 from '../../Services/goAPI2';

const LogoBlock=(props)=>{

  const [posts, setPosts]=useState([]);
  const page=props.page;
  const part=props.part;
    
  useEffect(()=>{

    
    var qString='exp/posts/?type=logos';
    if(props.testDomain){qString=qString + '&td='+ props.testDomain;}
   
    goAPI2.get(qString)
    
          .then(response => {
            if(response.data){
          
              setPosts(response.data);
             
            }})
            .catch(function (error) {
          })   
  },[page]);
  
 
  return (  
          <React.Fragment> 
          
            <div className="container ">
            
              {posts ? 
          
                <div className="client_logo_wrapper text-center pt-3" >
                  <h2 className="p-3">{part.headline}</h2>
                  
                    {posts.map((post, p)=>(
                    
                      <img  loading="lazy" key={p} src={`${ page.directory + post.image_file}`} width={post.custom + '%'}  className="client_logo_image" ></img>   
                    ))}
                </div>
            
              :<p>no posts</p>}

  
            </div>
          </React.Fragment>
        );
  
 }


export default LogoBlock;