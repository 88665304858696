


import React from 'react';

import {Link} from 'react-router-dom';

import {getExcerpt} from '../../Utility/StringOps';

const Project=(props)=>{

  const post=props.post;

  const template=props.template;
  const directory=props.directory;

  const getContent=()=>{

  var content=null;
  if(template=='summary'){
        
    content=(
        <div className="col d-flex justify-content-center">
            <div className="card card-wrapper card_project-wrapper" >
                <div className="card_project-body">
                    <div className="card-content text-start">
                        <Link to={'/'+post.link}>
                            <img  loading="lazy"  src={`${ directory + post.image_file}`} className="card-image"  />   
                        </Link>
                        <div className="card-body">
                          
                            <h4>{post.headline}</h4>
                        
                            <p className="card-text">{post.excerpt}</p>
                            
                            
                        </div>
                    </div>     
                </div>
                <div className=" d-flex flex-row-reverse p-3">
                    {post.link ? <Link to={'/'+post.link} className="card-link-more" >{post.button_label}&nbsp;  
                    <i className="bi-arrow-right"></i></Link>:null}
                </div>

            </div>
        </div>
    );
  }

  if(template=='detail'){
    console.log('article detail');
    console.log(post);
    content=(
          
        <div className="card card-wrapper-horiz" >
            <div className="card-content text-start">
                <div className="row ">
                    <div className="col-md-4 p-2 ">
                        <Link to={'/'+post.link}>
                            <img  loading="lazy" src={`${ directory + post.image_file}`} className="card-image-horiz " alt="..."/>
                        </Link>
                    </div>
                    <div className="col-md-8">
                        
                        <div className="card-body">
                            <h3 className="card-title pb-2">{post.headline}</h3>

                            <h4>Challenge</h4>
                            <p className="card-text">{post.challenge}</p>
                            <h4>Solution</h4>
                            <p className="card-text">{post.solution}</p>
                            <h4>Results</h4>
                            <p className="card-text">{post.results}</p>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
  }

    return content;
  }

   

  return (  
          
    <React.Fragment>
        {getContent()}
    </React.Fragment>
           
            
       );
  
 }


export default Project;







  