
import React from 'react';

import {Container,Row,Col,Card,Button}from 'react-bootstrap';
import {Link} from 'react-router-dom';

const SocialBadges = (props)=>{


  var brand=props.brand;

  return  ( 
    <React.Fragment>
      <div className="social_list p-3">
        {brand.facebook ? ( 
        <a  href={"https://facebook.com/" + brand.facebook + "/"}  >
          <div className="footer_social-link">
          <svg width="36" height="36" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            
                <path d="M25 12c0-6.627-5.596-12-12.5-12S0 5.373 0 12c0 5.99 4.572 10.955 10.55 11.858v-8.385H7.377v-3.466h3.173V9.36c0-3.005 1.865-4.667 4.72-4.667 1.37 0 2.798.238 2.798.238v2.95h-1.576c-1.554 0-2.035.923-2.035 1.873v2.252h3.47l-.551 3.466h-2.912v8.385c5.978-.903 10.55-5.868 10.55-11.858H25Z" fill="currentColor"></path>
                <path d="m17.369 15.466.55-3.466H14.45V9.748c0-.95.488-1.872 2.035-1.872h1.576V4.925s-1.427-.237-2.798-.237c-2.855 0-4.72 1.661-4.72 4.667V12H7.37v3.466h3.173v8.385c.636.095 1.286.15 1.95.15.664 0 1.314-.048 1.95-.15v-8.384h2.926Z" fill="#fff"></path>
            
            </svg>
          </div>
        </a>) : null}

        {brand.instagram ? (
        <a href={"https://instagram.com/" + brand.instagram + "/"} >
        <div className="footer_social-link">
        <svg width="36" height="36" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      
                <path d="M12.5 24C19.404 24 25 18.627 25 12S19.404 0 12.5 0 0 5.373 0 12s5.596 12 12.5 12Z" fill="currentColor"></path>
                <path d="M16.38 7.462c-.474 0-.863.366-.863.828 0 .46.382.827.862.827s.862-.366.862-.828c0-.46-.381-.827-.862-.827ZM12.564 8.52c-1.993 0-3.611 1.553-3.611 3.466 0 1.913 1.618 3.467 3.61 3.467 1.993 0 3.611-1.554 3.611-3.467s-1.618-3.466-3.61-3.466Zm0 5.685c-1.272 0-2.311-.998-2.311-2.219 0-1.22 1.039-2.218 2.31-2.218 1.272 0 2.311.997 2.311 2.218s-1.039 2.219-2.31 2.219Z" fill="#fff"></path>
                <path d="M15.425 19.02h-5.85c-2.431 0-4.403-1.898-4.403-4.225V9.178c0-2.334 1.972-4.226 4.403-4.226h5.85c2.431 0 4.403 1.893 4.403 4.226v5.617c0 2.333-1.979 4.226-4.403 4.226ZM9.575 6.276c-1.668 0-3.025 1.302-3.025 2.903v5.617c0 1.6 1.357 2.903 3.025 2.903h5.85c1.668 0 3.025-1.302 3.025-2.903V9.178c0-1.6-1.357-2.903-3.025-2.903h-5.85Z" fill="#fff"></path>
          
            
            </svg>
        </div>
        </a>
        ):null}

        {brand.twitter ? (

          <a href={"https://twitter.com/" + brand.twitter +"/"} className="">
            <div className="footer_social-link">
              <svg width="36" height="36" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                
                    <path d="M12.5 24C19.404 24 25 18.627 25 12S19.404 0 12.5 0 0 5.373 0 12s5.596 12 12.5 12Z" fill="currentColor"></path>
                    <path d="M19.41 7.89c-.43.189-.91.304-1.377.379.219-.034.544-.42.678-.577.198-.237.368-.508.46-.8.014-.02.02-.054 0-.068-.029-.013-.05 0-.071 0a7.339 7.339 0 0 1-1.633.604.11.11 0 0 1-.113-.027 1.363 1.363 0 0 0-.14-.143 2.976 2.976 0 0 0-.792-.468 2.948 2.948 0 0 0-1.216-.19 3.053 3.053 0 0 0-1.151.312 2.93 2.93 0 0 0-.933.726 2.71 2.71 0 0 0-.558 1.065c-.1.373-.085.746-.029 1.126.007.061 0 .075-.056.061-2.247-.319-4.106-1.092-5.618-2.747-.063-.075-.099-.075-.155 0-.664.95-.34 2.483.487 3.229.113.102.226.203.347.292-.043.006-.594-.048-1.082-.292-.063-.04-.098-.02-.106.054 0 .102 0 .197.022.312.127.963.82 1.859 1.773 2.205.113.047.24.088.36.108-.218.048-.437.082-1.06.034-.077-.013-.105.02-.077.095.466 1.221 1.477 1.588 2.233 1.798.099.013.205.013.304.04 0 .007-.014.007-.021.02-.248.367-1.124.639-1.527.774-.735.244-1.54.36-2.317.285-.128-.02-.149-.013-.184 0-.035.02 0 .048.035.081.156.102.318.19.48.272.495.25 1.011.454 1.548.597 2.784.74 5.922.197 8.006-1.798 1.64-1.567 2.219-3.724 2.219-5.888 0-.081.106-.129.162-.176.424-.306.77-.679 1.089-1.086.07-.095.07-.176.07-.21v-.013c0-.034 0-.028-.056 0v.013Z" fill="#fff"></path>
              
                </svg>
                </div>
            </a>

        ): null}
      

        {brand.pinterest ? (
          <a href={"https://pinterest.com/"+ brand.pinterest + "/"} className="">
            <div className="footer_social-link">
                <svg width="36" height="36" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                
                    <path d="M12.5 0C5.596 0 0 5.373 0 12c0 4.884 3.038 9.076 7.398 10.949-.014-.34-.028-.672-.021-1.011.042-1.167.19-1.567 1.76-7.896a.428.428 0 0 0-.036-.265c-.374-.97-.452-1.96-.12-2.957.707-2.157 3.258-2.327 3.703-.543.275 1.099-.452 2.544-1.01 4.674-.46 1.757 1.695 3.005 3.532 1.723 1.696-1.18 2.36-4.016 2.233-6.03-.247-4.01-4.826-4.871-7.73-3.582-3.328 1.478-4.084 5.44-2.586 7.251.19.23.339.373.275.604-.099.36-.183.726-.29 1.085-.077.272-.31.367-.593.258a3.484 3.484 0 0 1-1.392-.997c-1.279-1.52-1.64-4.518.05-7.062 1.872-2.815 5.348-3.955 8.521-3.609 3.795.414 6.19 2.904 6.642 5.726.205 1.289.057 4.456-1.823 6.695-2.162 2.578-5.667 2.747-7.285 1.167-.127-.122-.226-.265-.346-.407a3.31 3.31 0 0 0-.05.156c-.777 2.924-.862 3.568-1.66 4.925-.12.21-.262.407-.389.61 1.173.353 2.417.536 3.71.536 6.904 0 12.5-5.372 12.5-12 0-6.627-5.59-12-12.493-12Z" fill="currentColor"></path>
                    <path d="M10.84 17.929c.014-.048.028-.102.049-.156.12.142.219.285.346.407 1.618 1.58 5.123 1.41 7.285-1.167 1.88-2.239 2.028-5.413 1.823-6.695-.452-2.822-2.847-5.312-6.642-5.726-3.173-.346-6.656.794-8.522 3.61-1.688 2.543-1.32 5.541-.049 7.06.375.448.834.788 1.392.998.283.108.516.013.594-.258.106-.36.19-.726.29-1.085.063-.23-.085-.373-.276-.604-1.505-1.811-.75-5.773 2.586-7.251 2.904-1.29 7.483-.428 7.73 3.581.127 2.008-.537 4.844-2.233 6.03-1.844 1.283-3.999.035-3.533-1.722.559-2.13 1.286-3.568 1.01-4.674-.444-1.784-2.995-1.615-3.702.543-.325.997-.254 1.987.12 2.957.036.088.057.17.036.265-1.57 6.329-1.718 6.729-1.76 7.896-.014.339 0 .678.021 1.01.453.197.912.374 1.392.516.135-.203.269-.4.389-.61.798-1.357.883-2.008 1.66-4.925h-.007Z" fill="#fff"></path>
        
                
              </svg>
            </div>
          </a>
        ): null}


        {brand.linkedin ? ( 
          <a href={"https://linkedin.com/company/" + brand.linkedin + "/"} className="">
            <div className="footer_social-link">
              <svg width="36" height="36" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5 24C19.404 24 25 18.627 25 12S19.404 0 12.5 0 0 5.373 0 12s5.596 12 12.5 12Z" fill="currentColor"></path>
                <path fill="#fff" d="M19.997 18H20v-5.135c0-2.512-.54-4.447-3.477-4.447-1.412 0-2.36.775-2.746 1.51h-.04V8.652h-2.785V18h2.9v-4.63c0-1.218.23-2.397 1.74-2.397 1.486 0 1.508 1.391 1.508 2.476V18h2.896ZM6.231 8.653h2.903V18H6.23V8.653ZM7.681 4C6.753 4 6 4.753 6 5.681s.753 1.697 1.681 1.697 1.681-.769 1.681-1.697S8.61 4 7.682 4Z"></path>
              </svg>  
            </div>
          </a>)
      
        :null}
      </div>
  </React.Fragment>       
        );
}



export default SocialBadges;