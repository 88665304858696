


import React from 'react';
import {Container,Row,Col,Card,Button}from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { getRequest } from '../../Utility/RequestTools';
import {getExcerpt} from '../../Utility/StringOps';

const Policy=(props)=>{

  const post=props.post;
  const brand=props.brand;
  const rqst=getRequest();
  
  const template=props.template;
  
   

  return (  
          
    <React.Fragment>
            <div className="row">
                <div className="col  d-flex justify-content-center">
                    <div className="card article-wrapper" >
                        <div className="card-content ">
                         
                            <div className="card-body">
                                <h4>{post.headline}</h4>
                                <h5 className="card-text"> <div dangerouslySetInnerHTML={{__html: post.body}}></div></h5>
                                    
                                <div className=" d-flex flex-row">
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
             </div>
    </React.Fragment>
           
            
       );
  
 }


export default Policy;







  