


import React from 'react';

import {Link} from 'react-router-dom';

import {getExcerpt} from '../../Utility/StringOps';

const Skill=(props)=>{

  const post=props.post;

  const template=props.template;
  const directory=props.directory;

  const getContent=()=>{

  var content=null;
  if(template=='summary'){
        
    content=(
        <div className="col d-flex justify-content-center">
            <div className="card card_skill-wrapper" >

                   <div className="card-content text-start">
             
                    <div className="card-body">
                        <div className = "card_skill-excerpt">
                            <h3><i className="bi-1-circle p-2"/>{post.headline}</h3>
                            
                            <p className="card-text">{post.excerpt}</p>
                        </div>  
                        <div className=" d-flex flex-row">
                            {post.link ? <Link to={'/'+post.link} className="card-link-more" >Read More&nbsp;  
                            <i className="bi-arrow-right"></i></Link>:null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }

  if(template=='detail'){
   
    content=(
        <div className="card card-wrapper-horiz" >
            <div className="card-content text-start">
                <div className="row ">
                    <div className="col-md-4 p-0 ">
                        <div className="p-3">
                            <h2 ><i className="bi-1-circle"/> {post.headline}</h2>
                            <h4 className="p-4">{post.excerpt}</h4>
                        </div>
                </div>
                   
                    <div className="col-md-4  skill_detail-wrapper">
                        <div className="card-body skill_detail-wrapper">
                            <h4 className="card-title ">Experience</h4>
                                {post.skills.map((skill,s)=>(
                              
                                    <p class key={s}> <b>{skill.name}:&nbsp;</b>{skill.description}</p>
                                
                            ))}
                        </div>
                    </div>
                    <div className="col-md-4  skill_detail-wrapper">
                        <div className="card-body skill_detail-wrapper">
                            <h4 className="card-title  ">Roles</h4>
                                {post.roles.map((role,r)=>(
                                    <div>
                                    <p key={r}><b>{role.title}</b><br/>
                                    {role.company}
                                    </p>
                                   
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
               
        );
  }

    return content;
  }

   

  return (  
          
    <React.Fragment>
        {getContent()}
    </React.Fragment>
           
            
       );
  
 }


export default Skill;







  