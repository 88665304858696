
import React from 'react';
import DisplayPost from './DisplayPost';
import { useState,useEffect } from 'react';
import goAPI2 from '../../Services/goAPI2';

const Showcase=(props)=>{

  const [posts, setPosts]=useState([]);
  const page=props.page;
  const part=props.part;
    
  useEffect(()=>{

    const postType=props.part.post_type;
    var qString='exp/posts/?type='+postType +'&limit='+props.part.limit;
    if(page.tag1){qString='exp/posts/?type='+postType +'&limit='+props.part.limit +'&tag1='+page.tag1;}
    if(page.tag2){qString=qString +'&tag2='+page.tag2;}
    if(page.feature_name){qString=qString +'&exclude_name='+page.feature_name;}
    if(props.testDomain){qString=qString + '&td='+ props.testDomain;}
   
    goAPI2.get(qString)
    
          .then(response => {
            if(response.data){
              setPosts(response.data);
            }})
            .catch(function (error) {
          })   
  },[page]);

  

 
  return (  
          <React.Fragment> 
          
            <div className="container">
            
              {posts ? 
                <div className="text-center p-3">
                {props.part.title ? <h2>{part.title}</h2>:null}
             
                <div className="row">
                  {posts.map((post, p)=>(
                      <DisplayPost post={post} page={props.page} key={p} template='summary'></DisplayPost>
                  ))}
                </div> 
                </div>
              :<p>no posts</p>}
                 
            </div>
          </React.Fragment>
        );
  
 }


export default Showcase;