


import React from 'react';
import {Link} from 'react-router-dom';
import { getRequest } from '../../Utility/RequestTools';

const Attraction=(props)=>{

  const post=props.post;
  const rqst=getRequest();
  
  const directory=props.directory;
  const isBroker=props.isBroker;

  const showVendorLink=()=>{
    var vendor=false;
    if((isBroker) && (post.company_label!=='BounceHouse.com')){
        vendor = true;
    }
    
    return vendor;
  }
  
  var backLink=false;
  if(rqst.params){backLink=rqst.params['path']};
  
  return (  
        <React.Fragment>
            {post  ?  
            <div className="col">
                <div className="card card-wrapper card_attraction-wrapper" >
                    <div className="card-content text-start">
                        
                        
                        <Link to={"/request-quote/?offer=results&postid="+post.id}>
                            <img  loading="lazy"  src={`${ directory + post.image_file}`} className="card-image"  /> 
                        </Link> 
                        
                        <div className="card-body card_attraction-body">
                            <h4>{post.headline}</h4>

                            {showVendorLink() ?
                            
                            <div>
                                <b>Provided by:</b><Link className="card_vendor-link"  to={'/'+ post.company_link}> {post.company_label}</Link>
                            </div>
   
                            : null}

                            <p className="card-text">{post.excerpt}</p>
                          
                            <ul>
                                <li className="card-list-item">Price:&nbsp;{post.price}</li>
                                <li className="card-list-item">Rental:&nbsp;{post.time}</li>
                            </ul>
                    
                        </div> 
                        <div className=" d-flex flex-row">
                            <Link to={"/request-quote/?offer=results&postid="+post.id} className="mx-auto"><button className="button-icon  my-3 ">Request Quote</button></Link>   
                        </div>

                    </div>
                </div>
            </div>
            :null}
        </React.Fragment>
            
       );
  
 }


export default Attraction;







  