
import React from 'react';
import goAPI2 from '../Services/goAPI2';
import {getRequest} from './RequestTools';

export function recordActivity(params){

      //request details
      const rqst=getRequest();
      var activity={};
      if(params.name){
            activity.name=params.name;
            delete params.name;
      }
      activity.params=params;
      activity.source=rqst.domain;
      activity.source_id='allsites';
      
      
      var referral=getReferral();
      if(referral){
            activity.referral_source_id=referral.value;
            activity.referral_source=referral.key;
      }
      var activityString=JSON.stringify(activity);
      goAPI2.post('cdp/activities/',activityString)
}

export function setReferral(){

      const rqst=getRequest();
      var strReferral=false;
      
      if(rqst.params){
            var params=rqst.params; 
            var referral={};
            
            if(params.gclid){
                  referral.key='gclid';
                  referral.value=params.gclid;
            }
            if(params.qid){
                  referral.key='qid';
                  referral.value=params.qid;
            }

            if(referral){
                  var strReferral=JSON.stringify(referral);
                  localStorage.setItem('referral',strReferral);}
      }
}

export function getReferral(){
      var referral=false;
      var strReferral=localStorage.getItem('referral');
      if(strReferral){
            referral=JSON.parse(strReferral);
      }
      return referral;
}
