import React from 'react';
import Menu from './Menu';
import FormMgr from '../../Component/Form/FormMgr';
import goAPI2 from '../../Services/goAPI2';
import SocialBadges from '../Store/SocialBadges';
import CookieConsent from 'react-cookie-consent';
import ReactGA from 'react-ga4';
import {getRequest} from '../../Utility/RequestTools';
import { useState, useEffect } from 'react';
import SubscribeForm from './SubscribeForm';
import {Link} from 'react-router-dom';

const Layout=(props)=>{ 

  const [data, setData] = useState(false);
  

  useEffect(() => {
    const fetchData = async () => {

      var brandQString='exp/brands/';
      var menuQString='exp/menus/';
      if(props.testDomain){
        brandQString=brandQString + '&td=' +props.testDomain;
        menuQString=menuQString + '&td='+ props.testDomain;
      }  
      
      const brandResp = await  
        goAPI2.get(brandQString);

      const menuResp=await  
        goAPI2.get(menuQString)

        setData({ brand:  brandResp.data, menus: menuResp.data });
        }
     fetchData();    
  }, [page]);
  

  var brand=data.brand;

  var menus=data.menus;
  var page=props.page;
  var directory=page.directory;
  var rqst=getRequest();

  //update favicon
  
  if(data){
    //update favicon
    var favicon = document.getElementById("favicon");
    favicon.href= directory + brand.favicon;

    if(brand.analytics_tag!==undefined){
        ReactGA.initialize(brand.analytics_tag);
    }
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: rqst.path, title: page.title });
    document.title=brand.site_name + " | "+ page.title; 

  }

  return (  
    <div>
      {data ? 
      <div>
        <div className="container-fluid header-wrapper">
          <div className="container">

            <nav className="navbar  navbar-expand-sm ">
          
                <div class= "navbar-brand">
                  <Link to="/">{brand ? <img src={directory + brand.primary_logo}  width={brand.primary_logo_width + '%'} alt={brand.image_alt} />:null}</Link>  
                </div>
                <button type="button" data-bs-toggle="collapse" class="navbar-toggler"  
                  data-bs-target="#navbarNav" aria-controls="navbarNav" 
                  aria-expanded="false" aria-label="Toggle navigation">
                  <i className="bi-list"/>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                  
                  <div className="navbar-nav">
                    {menus ? <Menu  menus={menus}  menuName="main"></Menu> :null}
                  
                      {menus ? <React.Fragment>
                              <Menu  menus={menus} menuName="main-right" ></Menu>
                              {/**roles=='public,all' ? null : <Link to={'/auth/logout'} ><Button className="button-icon">Logout</Button></Link>/}
                            */}
                            </React.Fragment>
                      :null}  
                      
                  </div>
                </div>
      
            </nav>
          </div>
        </div>

        {props.children}
      
        <div className="container-fluid footer-wrapper mt-3">
          <div className="container text-center py-3 ">
            <div className="row ">
              <div className="col">
                <div className="row">
                  <a href="/" >
                    {brand.secondary_logo ? <img src={directory + brand.secondary_logo} loading="lazy" width={brand.secondary_logo_width+ '%'} alt=""/>:null}
                  </a>
                </div>
                <div className="row">
                      <SocialBadges brand={brand}></SocialBadges> 
                </div>
              </div>
                  
              <div className="col-md-2 text-start">
                  {menus ? <Menu menus={menus}  menuName="footer-left"></Menu> :null}
              </div>
                  
              <div className="col-md-2 text-start">
                  {menus ? <Menu  menus={menus}  menuName="footer-right"></Menu> :null}
              </div>
                  
              <div className="col-md-3">
                      {brand.subscribe_form ? 
                          <React.Fragment>
                            <h5 className="mb-2">Subscribe for news and offers!</h5> 
                            <SubscribeForm></SubscribeForm>
                          </React.Fragment> :null}
              </div>
            </div>      

          {/**super footer */}
          <div className="p-3 ">
            <div className="row justify-content-center ">
              <div className="col text-end">
                {menus ? <Menu menus={menus}  menuName="policies"></Menu> :null}
              </div>
            </div>
          </div>
        
          {brand.cookie_consent ? 
          <CookieConsent
                    location="bottom"
                    buttonText="Agree"
                    cookieName="myAwesomeCookieName2"
                    style={{ background: "#9886c5" }}
                    buttonStyle={{
                      background: "",
                      color: "black",
                      fontWeight: "bolder",
                    }}
                    expires={150}
                >
                This website uses cookies to enhance the user experience.{" "}
          </CookieConsent>:null}
                
          {brand.copyright ? 
            <React.Fragment>
                  <hr/>
                  <p className="footer_policy-link" >&copy;2023-2024  {brand.copyright}. </p> 
            </React.Fragment>:null
          }
          </div>
          </div>
        </div>
        :null
        }
      </div>
      );
      }

    export default Layout;





