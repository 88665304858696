

export function unpack(post){

    var keys=false;
    var updatedPost=false;
    var custom=false;
    var params=false;

    updatedPost=post;
    
    if((post.custom!=='') && (post.custom!==null)  &&(post.custom!==undefined)){
        custom=JSON.parse(post.custom);
        keys=(Object.keys(custom));
        keys.map((key,k)=>{
        updatedPost[key]=custom[key]});
    }
    if((post.params!=='') && (post.params!==null) &&(post.params!==undefined)){
        params=JSON.parse(post.params);
        keys=(Object.keys(params));
        keys.map((key,k)=>{
        updatedPost[key]=params[key]});
    }
      return updatedPost;
}



export function listAllFields(fields){

    var allFields=[];
    fields.map((field,f)=>{
        allFields.push(field);
        if(field.subfields!==undefined){
            field.subfields.map((sub,s)=>{
            allFields.push(sub);
          })
        }
    })
   
    return allFields;
}