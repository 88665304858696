import React, { Component } from 'react';
import {Table,Container,Button,Row,Col,Form, FormGroup,FormControl,Checkbox} from 'react-bootstrap';
import goAPI2 from '../../Services/goAPI2';

import {properCase,plural} from '../../Utility/StringOps';


import  {Link} from 'react-router-dom';
import FormMgr from '../../Component/Form/FormMgr';
import {getRequest} from '../../Utility/RequestTools';

const PostList=(props)=>{

  const rqst=getRequest();

  var postTypeName="attractions";//rqst.params['posttype'];
  
  const [posts, setPosts]=React.useState([]);
 
  
  const addFormDefaults={"type":postTypeName};

  const postTypes=[
    {"name":"attractions","tag":"service","show_image":true,"show_delete":true,"show_limit":true,"show_status":true},
    {"name":"policies","tag":false,"show_image":true,"show_delete":true,"show_limit":true,"show_status":true},
    {"name":"profiles","tag":"location","show_image":true,"show_delete":true,"show_limit":true,"show_status":true},
    {"name":"ads","tag":false,"show_image":true,"show_delete":true,"show_limit":true,"show_status":true},
  ]
  
  const postType=postTypes.find((postType)=>(postType.name==postTypeName));
  const tag=postType.tag;

  React.useEffect(()=>{
    getPosts();
     
  },[]);

  const getPosts=()=>{
    goAPI2.get('mgr/posts/?type='+ postTypeName)
    
          .then(response => {
            if(response.data){
              
              setPosts(response.data);
              
            
            }})
            .catch(function (error) {
          })  
  }

  
  const handleStatus=(e)=>{
    
    e.preventDefault();
   
    var tPost=posts.find(post=>post.id==e.target.id);
    var oldStatus=tPost.status;
    var newStatus='active';
    if(oldStatus=='active'){newStatus='inactive';}
  
    var paramString={"id":e.target.id,"status":newStatus};
    var jsonString=JSON.stringify(paramString);
    goAPI2.post('exp/posts/',jsonString)
        
    .then(response => {
      if(response.data){
        getPosts();
      }})
    .catch(function (error) {
      console.log(error);
    })  
  }

  const handleRemove=(e)=>{
    e.preventDefault();
    goAPI2.delete('exp/posts/'+ e.target.id + '/')
    .then(response => {
      if(response.data){
        getPosts();
      }}) 
  }
  console.log(postType);
 
  var itemsLeft=1;
  
    return ( 
                <Container>
                  <Row>
                    <Col  className="mx-auto" lg={6}>
                    
                        <h3>PostList</h3>

                        <Table striped bordered hover  >
                          <thead>
                            <tr>
                              <th>{properCase(postTypeName)}</th>
                              {postType.show_image==true ?  <th>Image</th> : null}
                              {postType.tag ?
                                <th >{properCase(postType.tag)}</th>
                                :null}
                      
                               {/**<th>Preview</th>**/}
                               {postType.show_status==true ?  <th>Status</th> : null}
                             
                           
                            </tr>
                          </thead>
                          <tbody>
                            {posts.map((post, p)=>(
                              <tr key={p}>
                                
                                <td><Link className="grid-link" to={"/manager/edit/?posttype="+post.type+"&id="+post.id}>{post.headline} </Link></td>

                                  {postType.show_image==true ?   <td> <Link className="grid-link" to={"/manager/image/?id="+post.id+"&posttype="+post.type}>Edit Image</Link></td>:null}
                                    
                                                  <td ><Link className="grid_style_link" to={"/manager/tags/?postname="+post.name+"&tagtype="+tag+"&id="+post.id}>{properCase(tag)}</Link></td>
                                    
                                  
                                  {postType.show_status==true ? 
                                    <td><Link to="" className="grid-link"   onClick={handleStatus} id={post.id}>{properCase(post.status)}</Link></td>
                                    :null
                                  }  
                                  {postType.show_delete==true ? 
                                  <td><Link to=""><i className="bi-trash" onClick={handleRemove} id={post.id}></i></Link></td>
                                    :null
                                  }                     
                                </tr>))
                            }
                          </tbody>
                        </Table>


                        {/**(itemsLeft>0)  ? 
                          <FormMgr   form={props.section.form} defaults={addFormDefaults} callback=''></FormMgr> 
                     
                          :null
                          **/}

                        {postType.show_limit ? 
                          <p>{itemsLeft +" of " + 'lim' +" available "+ plural(postTypeName) + " remaining."}</p>
                          :null
                        }
                        
                        
                    </Col>
                  </Row>
                  <Row>
                   
                  </Row>            
                    

                </Container>
              
            );
}
export default PostList;