
import React from 'react';
import SocialBadges from './SocialBadges';
import { useState } from 'react';
import goAPI2 from '../../Services/goAPI2';

  const Profile=(props)=>{


    const [post, setPost]=useState([]);

    const [toggle, setToggle]=React.useState(false);


    const toggleContact=()=>{

      if(toggle==false){
        //record event
        //recordActivity({'description':'contact details for '+post.name,'targetpostid':post.id, 'subtype':'view contact'});

      }
      setToggle(!toggle)
      
    }

    const part=props.part;
    var featureName=false;
    var directory=props.page.directory;
    
    if(props.page){
      featureName=props.page.feature_name;
      
    }
    
    React.useEffect(()=>{
      var qString='exp/posts/?type=' + part.post_type + '&name='+ featureName;
      if(props.testDomain){qString=qString + '&td='+ props.testDomain;}
      console.log(qString);
      goAPI2.get(qString)
      
            .then(response => {
              if(response.data){
                var posts=response.data;
                var post =posts[0];
                console.log(response.data);
                setPost(post);
              
              }})
              .catch(function (error) {
            })   
    },[featureName]);

    
    return (  
              <div className="container">
                {post ?
                <div className  = "card card-wrapper-horiz">
                  <div className="container text-center vendor_contact-wrapper">
                    <div className="row justify-content-center">
                     
                      {post ? (
                      <React.Fragment>

                        <div className="row align-items-center">
                          <div className="col-md-3">
                            <img src={directory + post.image_file} loading="lazy" width="159" alt=""/>
                          
                          </div>
                          <div className="col-md-6">

                              <div className="vendor_contact_title">{post.headline}</div>
                            <div className="vendor_contact_intro">{post.excerpt}</div>
                            
                          </div>   
                          <div className="col-md-3">
                            <button className="button-icon mx-auto" onClick={toggleContact}>Contact</button>
                          </div>   
                        </div>
                        
                    
                       
                        
                          { toggle==true ?

                            <div className="vendor_contact">
                              <div>
                                <a className="vendor_contact-link" href={"https://"+post.website}><i className="bi-box-arrow-up-right vendor_contact_icon" ></i>{post.website}</a> 
                                <i className="bi-envelope vendor_contact_icon" ></i>{post.email}
                                <i className="bi-telephone vendor_contact_icon" ></i>{post.phone}
                              </div>
                              <div className="row">
                                <SocialBadges brand={post}></SocialBadges>
                              </div>   
                            </div>
                          :null}

                        </React.Fragment>
                      ):null}
                    </div>
                  </div>
                </div>
          
                     
               :null}
              </div>
          );

  }
    
export default Profile;