
import React from 'react';
import { Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';






const MenuItem=(props)=>{
  
  const element=props.element;
 

  const getItem=()=>{
    var item=null;
    
    if(element.type=='button'){item=(<Link to={'/'+element.link} ><button className="button-icon mt-3">{element.label}</button></Link>)};
    if(element.type=='list-item'){item=(<div><Link  to={'/'+element.link} className="footer-link" >{element.label}</Link></div>)};
    if(element.type=='list-item-ext'){item=(<div><a href={'https://' + element.link} className="footer-link" >{element.label}</a></div>)};
    if(element.type=='dropdown-item'){item=(<div><Link  to={'/'+element.link} className="dropdown-item" >{element.label}</Link></div>)};
    
    if(element.type=='policy-link'){item=(<Link  to={'/'+element.link} className="footer_policy-link" >{element.label}</Link>)};
    if(element.type=='nav-link'){item=(<Link  to={'/'+element.link} className="nav-link" >{element.label}</Link>)};
    //if(element.type=='vendor-profile' && props.content.session.profilename){item=(<div><Link  to={'/vendors/'+ props.content.session.profilename +'/'} target="_blank" className="nav-link" >Preview</Link></div>)};
    return item;
  }

  
  return(

    <React.Fragment>
      {getItem()}
    </React.Fragment>
      
  );


}  


export default MenuItem;
    
 
