
import React  from 'react';
import FormMgr from '../../Component/Form/FormMgr';
import { useState, useEffect } from 'react';
import goAPI2 from '../../Services/goAPI2';

const SubscribeForm=(props)=>{

  const [form, setForm] = useState(false)
  
  useEffect(() => {
    const fetchData = async () => {
      const formResp = await  
        goAPI2.get('exp/forms/?name=subscribe')

        setForm(formResp.data);
        }
     fetchData();    
  }, []);

  
  return (
    <div>
      {form ? <div>
                <FormMgr  form={form}></FormMgr>
              </div>
      :null}
    </div>                
  );
 
}

export default SubscribeForm;