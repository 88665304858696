
import React from 'react';



const ContentBlock=(props)=>{

  
  
  const content=props.part.content;
  
  return (  
          <div className="container">
            <div className="row">
         
              <div dangerouslySetInnerHTML={{__html: props.part.content}}></div>
                     
            </div>
          </div>
       );
  
 }


export default ContentBlock;