
import React from 'react';
import DisplayPost from './DisplayPost';
import { useState } from 'react';
import goAPI2 from '../../Services/goAPI2';

  const FeaturedPost=(props)=>{


    const [post, setPost]=useState([]);
 
    var featureName=false;
    if(props.part.name){
      featureName=props.part.name;
    }else{
        featureName=props.page.feature_name;
        }
    

    React.useEffect(()=>{
      var qString='exp/posts/?name='+ featureName;
      if(props.testDomain){qString=qString + '&td='+ props.testDomain;}
      goAPI2.get(qString)
      
            .then(response => {
              if(response.data){
                var posts=response.data;
                var post =posts[0];
                console.log(response.data);
                setPost(post);
              
              }})
              .catch(function (error) {
            })   
    },[featureName]);

    var template='detail';
    if(props.part.template){template=props.part.template;}
    
    return (  
              <div className="container">
                {post ?
                <div className="row justify-content-center">
                 
                      {<DisplayPost post={post} page={props.page}  template={template}></DisplayPost>}
          
                </div> :null}
              </div>
          );

  }
    
export default FeaturedPost;